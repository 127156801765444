<template>
  <v-app class="ventaApp" :style="styles">
    <v-fade-transition>
      <router-view />
    </v-fade-transition>
    <!-- LOADING SCREEN -->
    <v-overlay :value="loadingScreen">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- APP ALERTS -->
    <base-snackbar
      :text="snackbar.text"
      :snackbar-active="snackbar.active"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :position="{
        top: snackbar.top,
        bottom: snackbar.bottom,
        right: snackbar.right,
        left: snackbar.left,
        centered: snackbar.centered,
      }"
      @close="closeSnackbar()"
    />
  </v-app>
</template>

<script>
import BaseSnackbar from "@/components/Snackbar/BaseSnackbar.vue";
export default {
  name: "App",
  components: {
    BaseSnackbar,
  },
  mounted() {
    const url = window.location.href.split("/");
    this.$store.commit("SET_URL_CONVENIO", url[2]);
  },

  computed: {
    snackbar() {
      return this.$store.getters.GetSnackbar;
    },
    loadingScreen() {
      return this.$store.getters.GetLoadingScreen;
    },
    styles() {
      let customStyles = this.$store.getters.getCustomStyles;
      return {
        "--bg-color":
          customStyles.background_color !== ""
            ? customStyles.background_color
            : "#F4F7FC",
        "--btn-color":
          customStyles.boton_color !== ""
            ? customStyles.boton_color
            : "#1482CC",
        "--btn-color-text":
          customStyles.boton_color !== ""
            ? this.darkorlightStyle(customStyles.boton_color)
            : "#F5F5F5",
        "--navbar-color":
          customStyles.navbar_color !== ""
            ? customStyles.navbar_color
            : "#F5F5F5",
        "--menu-color":
          customStyles.menu_color !== "" ? customStyles.menu_color : "#F5F5F5",
        "--menu-icon-color":
          customStyles.menu_color !== ""
            ? this.darkorlightStyle(customStyles.menu_color)
            : "#F5F5F5",
        "--box-color":
          customStyles.box_color !== "" ? customStyles.box_color : "#FFFFFF",
        "--text-color":
          customStyles.texto_color !== ""
            ? customStyles.texto_color
            : "#757575",
        "--title-color":
          customStyles.titulo_color !== ""
            ? customStyles.titulo_color
            : "#212128",
        "--icon-color":
          customStyles.icono_color !== ""
            ? customStyles.icono_color
            : "#FFFFFF",
      };
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("setSnackbar", { active: false });
    },
    darkorlightStyle(color) {
      let c = color.substring(1); // strip #
      var rgb = parseInt(c, 16); // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff; // extract red
      var g = (rgb >> 8) & 0xff; // extract green
      var b = (rgb >> 0) & 0xff; // extract blue

      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      let newColor = "#5F6368";
      if (luma < 128) {
        newColor = "#F5F5F5";
      }
      return newColor;
    },
  },
};
</script>

<style>
@media print {
  body,
  HTML * {
    visibility: hidden;
  }
  .table * {
    visibility: visible;
  }
  .table {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/Fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/Fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SansProRegular";
  src: local("SansProRegular"),
    url("./assets/Fonts/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SansProBold";
  src: local("SansProBold"),
    url("./assets/Fonts/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SansProSemiBold";
  src: local("SansProSemiBold"),
    url("./assets/Fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}
</style>
