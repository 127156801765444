import {
  companyLogoName,
  companyNumber,
  defaultCompanyUrl,
  defaultLogoPropName,
} from "@/services/file.js";

export default {
  computed: {
    logoName: () => defaultLogoPropName,
  },
  methods: {
    handleLogoError(e, item) {
      console.log(`--- Error al procesar la imagen: ${e}`);
      item[defaultLogoPropName] = `${defaultCompanyUrl}/${companyLogoName}${
        e.includes(defaultCompanyUrl) ? "" : item?.[companyNumber] ?? ""
      }.png`;
    },
  },
};
