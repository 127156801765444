const getters = {
  getQuotationValidate: (state) => {
    return state.quotation_validation;
  },

  getStatusQuotation: (state) => {
    return state.creatingQuotation;
  },
  getErrorQuotation: (state) => {
    return state.errorQuotation;
  },

  getErrorRetake: (state) => {
    return state.errorRetake;
  },
  getRetaking: (state) => {
    return state.retaking;
  },

  getNumberQuotation: (state) => {
    return state.numberQuotation;
  },

  getOffersQuotation: (state) => {
    return state.offersQuotation;
  },

  getQuotationState: (state) => {
    return state.quotationState;
  },

  getCities: (state) => {
    return state.cities;
  },
  getCitiesTwo: (state) => {
    let data;
    try {
      data = JSON.parse(state.cities);
    } catch {
      data = state.cities;
    }
    return data;
  },
  getCompanyLogo: (state) => (logoName) => {
    return state.companyLogo[logoName];
  },

  getBuyCart: (state) => {
    return state.buy_cart;
  },

  shoppingCart: (state) => {
    let data;
    try {
      data = JSON.parse(state.buy_cart_resume);
    } catch {
      data = state.buy_cart_resume;
    }
    return data;
  },

  getProposalData: (state) => {
    let data;
    try {
      data = JSON.parse(state.proposal);
    } catch {
      data = state.proposal;
    }
    return data;
  },

  tarifa: (state) => {
    let data;
    try {
      data = JSON.parse(state.tarifa);
    } catch {
      data = state.tarifa;
    }
    return data;
  },

  documentos: (state) => state.documents.regular,
  documentosPendientes: (state) => state.documents.pending,
  coberturas: (state) => state.documents.toppings,

  getMPayState: (state) => {
    let mpayData;
    try {
      mpayData = JSON.parse(state.mpay);
    } catch {
      mpayData = state.mpay;
    }
    return mpayData;
  },

  getPMBlocked: (state) => {
    return state.blocked_by_payment_method;
  },

  getMpay2Blocked: (state) => {
    return state.mpay2Blocked;
  },

  getStatus: (state) => {
    return state.creating;
  },
  getError: (state) => {
    return state.error_creating;
  },

  getPaymentsMethods: (state) => {
    return state.payment_methods;
  },

  getTarifaPayments: (state, getters) => {
    const tarifaMediosPago = [...(getters.tarifa.medio_pago ?? [])];
    tarifaMediosPago?.forEach((e) => {
      e.loading = false;
    });
    return tarifaMediosPago;
  },

  getBeneficts: (state) => {
    return state.beneficts;
  },

  getStep: (state) => {
    return state.step;
  },

  getProposalGood: (state) => {
    return state.proposal_good;
  },

  getInsurableInterestList: (state) => {
    return state.InsurableInterestList;
  },

  getColorsData: (state) => {
    return state.colors;
  },

  getProposalD: (state) => {
    return state.proposal;
  },

  getProposalStepData: (state) => {
    return state.dataProposalStep;
  },

  getDocumentType: (state) => state.documentType,
  getGreenSale: (state) => state.greenSale,
};

export default getters;
