import axios from "axios";
import { errors as errorCodes } from "@/store/resources/actionCodes.js";
import { endpoints } from "@/helpers/endpoints.js";

const state = {
  loadingstatus: false,
  token: sessionStorage.getItem("token") || "",
  status: "",
  usermodal: false,
  updateState: null,
  user: sessionStorage.getItem("user") || null,
  lastSession: sessionStorage.getItem("lastSession") || 0,
  perfil: "",
  // profileData: sessionStorage.getItem("userProfile") || {},
  token_temporal: sessionStorage.getItem("token_temporal") || {},
  clave_temporal: sessionStorage.getItem("clave_temporal") || {},
  canales: JSON.parse(sessionStorage.getItem("canales")) || [],
  canal_selected: JSON.parse(sessionStorage.getItem("canal_selected")) || {},
  sucursales: JSON.parse(sessionStorage.getItem("sucursales")) || [],
  sucursal_selected:
    JSON.parse(sessionStorage.getItem("sucursal_selected")) || {},
  canal_autorizacion: "",
  sucursal_autorizacion: "",
  convenio_url: sessionStorage.getItem("url_convenio") || "",
  actionCodes: {
    error: errorCodes,
  },
};
const actions = {
  login({ commit, getters, dispatch }, payload) {
    const url = endpoints.auth.base;
    const data = {
      usuario: payload.username,
      clave: payload.password,
      convenio: getters.url,
      returnSecureToken: true,
      modulo: "venta",
    };
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url,
        data,
      })
        .then(async (response) => {
          const lastUserLogin = response.data.data.fecha_hora_conexion || 0;
          const token = response.data.data.token;
          // Asignar el token
          commit("REFRESH_TOKEN", token);
          // Recuperar detalle del usuario
          const userResponse = await dispatch("user");
          if (userResponse.userData.cambiar_clave) {
            // Se requiere que el usuario cambie su contrasena para ingresar
            commit("DESTROY_TOKEN");
            commit("SET_TOKEN_TEMPORAL", token);
          } else {
            // Acceso normal
            commit("setLastSession", lastUserLogin);
            userResponse.token = token;
          }
          resolve(userResponse);
        })
        .catch((e) => {
          const error = e.response.data;

          const code = error.codigo_accion || 0;
          const errorData =
            getters.errorCodes[`${code}`] || getters.errorCodes["4109"];

          localStorage.removeItem("token");
          reject({
            code: errorData.code,
            message: errorData.message,
            type: errorData.type,
            onInput: errorData.onInput,
            input: errorData.input,
          });
        });
    });
  },

  async signIn({ commit, dispatch }, token = "") {
    commit("REFRESH_TOKEN", token);
    try {
      const response = await dispatch("user");
      if (response.canal.length > 0) {
        commit("setChannels", response.canal);
        await dispatch("REQUEST_CHANNEL_INFO", response.canal[0], {
          root: true,
        });
      }
      return response;
    } catch (error) {
      throw {
        info: "No se ha logrado ingresar a la plataforma de venta.",
        result: error,
      };
    }
  },

  user({ commit }) {
    const url = endpoints.user.data;
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url,
      })
        .then((response) => {
          const data = response.data.data;
          commit("SET_USERDATA", data.userData);
          resolve(data);
        })
        .catch(() =>
          reject(
            new Error("No se ha logrado recuperar la información de usuario")
          )
        );
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      commit("CLEAR_USER_DATA");
      resolve("ok");
    });
  },

  tokenRefresh({ commit }, payload) {
    //Payload => Id del canal actual
    const url = endpoints.auth.refresh;
    const canalId = payload;
    return new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url,
        data: {
          id_canal: canalId,
        },
      })
        .then((response) => {
          const token = response.data.data.token;
          commit("REFRESH_TOKEN", token);
          resolve("ok");
        })
        .catch((error) => {
          console.warn(error.response.data);
          reject(new Error("No se ha logrado actualizar el token."));
        });
    });
  },
  // Recuperar contrasena de usuario
  passwordRecovery({ commit }, payload) {
    const url = endpoints.user.passwordRecovery;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "POST",
        data: payload,
      })
        .then((response) => {
          commit("usermodal", true);
          const result = response.data.data;
          resolve(result);
        })
        .catch(() =>
          reject(
            new Error(
              "Ha ocurrido un error al intentar recuperar la contraseña."
            )
          )
        );
    });
  },
  // Actualizar la contrasena de usuario
  updateClave({ commit }, payload) {
    const url = endpoints.user.passwordChange;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "PUT",
        headers: { Authorization: `Bearer ${payload.token}` },
        data: {
          clave_actual: payload.clave_temporal,
          clave_nueva: payload.newpassword,
        },
      })
        .then(() => {
          commit("loadingstatus", false);
          resolve("La contraseña se ha actualizado correctamente!");
        })
        .catch(() => {
          reject(
            new Error("No se ha logrado actualizar la contraseña del usuario.")
          );
        });
    });
  },

  async UpdateProfile({ commit, dispatch }, payload) {
    commit("changeUpdateStatus", true);
    const data = {
      nombre: payload.nombre,
      primer_apellido: payload.primer_apellido,
      segundo_apellido: payload.segundo_apellido,
      celular: payload.celular,
      correo_electronico: payload.correo_electronico,
      usuario: payload.usuario,
      clave_actual: payload.clave_actual,
      clave_nueva: payload.clave_nueva,
      foto_perfil: payload.foto_perfil,
      extension: payload.extension,
      tipo_archivo: "img",
      nombre_archivo: "foto_perfil.png",
    };
    const url = endpoints.user.base;
    return new Promise((resolve, reject) => {
      axios({
        method: "PUT",
        url,
        data,
      })
        .then(async () => {
          await dispatch("user");
          resolve("El perfil de usuario ha sido actualizado exitosamente!");
        })
        .catch(() => {
          reject(
            new Error(
              "Ha ocurrido un error al intentar actualizar el perfil de usuario."
            )
          );
        })
        .finally(() => commit("changeUpdateStatus", false));
    });
  },

  async GetCanales({ commit, dispatch }, data) {
    const canales = {
      label: "Canal",
      answers: [],
      selected: "",

      id: [],
      rules: [(v) => !!v || "Canal es requerido"],
    };
    axios
      .get(`convenios/${data.convenio}/canales`)
      .then((res) => {
        const response = res.data.data.lista_canal;
        const canalSelected = response.filter(
          (it) => it.canal === parseInt(data.canal)
        );

        if (data.canal !== null) {
          canales.selected = canalSelected[0].canal;

          commit("SET_CANAL_AUTORIZACION", canalSelected[0].canal_descripcion);
        }
        commit("SET_CANAL", {
          label: response[0].canal_descripcion,
          value: response[0].canal,
        });
        dispatch("GetSucursales", {
          convenio: data.convenio,
          canal: response[0].canal,
        });
        response.map((it) => {
          canales.answers.push({
            label: it.canal_descripcion,
            value: it.canal,
          });
        });

        commit("SET_CANALES", canales);

        if (res.data.data.lista_canal.length >= 1 && data.canal !== null) {
          dispatch("GetSucursales", {
            convenio: data.convenio,
            canal: canalSelected[0].canal,
            sucursal: data.sucursal,
          });
        } else {
          dispatch("GetSucursales", {
            convenio: data.convenio,
            canal: res.data.data.lista_canal[0].canal,
            sucursal: data.sucursal,
          });
        }
      })
      .catch(() => {});
  },
  async GetSucursales({ commit }, data) {
    const sucursales = {
      label: "Sucursal",
      answers: [],
      selected: "",

      id: [],
      rules: [(v) => !!v || "Sucursal es requerido"],
    };

    axios
      .get(`convenios/${data.convenio}/canales/${data.canal}/sucursales`)
      .then((res) => {
        const sucursalSelected = res.data.data.Sucursales.filter(
          (it) => it.sucursal === parseInt(data.sucursal)
        );

        if (data.sucursal !== null) {
          sucursales.selected = sucursalSelected[0].sucursal;

          commit(
            "SET_SUCURSAL_AUTORIZACION",
            sucursalSelected[0].sucursal_descripcion
          );
        }
        commit("SET_SUCURSAL", {
          label: res.data.data.Sucursales[0].sucursal_descripcion,
          value: res.data.data.Sucursales[0].sucursal,
        });
        res.data.data.Sucursales.map((it) => {
          sucursales.answers.push({
            label: it.sucursal_descripcion,
            value: it.sucursal,
          });
        });

        commit("SET_SUCURSALES", sucursales);
      })
      .catch(() => {});
  },

  closeDialog({ commit }) {
    commit("usermodal");
  },
};

const getters = {
  loadingstatus: (state) => state.loadingstatus,
  usermodal: (state) => state.usermodal,
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  user: (state) => {
    let data;
    try {
      data = JSON.parse(state.user);
    } catch {
      data = state.user || null;
    }
    return data;
  },
  lastSession: (state) => {
    try {
      return parseInt(state.lastSession);
    } catch {
      return state.lastSession || 0;
    }
  },
  UserData: (state) => state.profileData,
  updateStatus: (state) => state.updateState,
  token: (state) => state.token,
  token_temporal: (state) => state.token_temporal,
  clave_temporal: (state) => state.clave_temporal,
  getCanales: (state) => state.canales,
  canal: (state) => state.canal_selected,
  sucursal: (state) => state.sucursal_selected,
  sucursales: (state) => state.sucursales,
  canal_aut: (state) => state.canal_autorizacion,
  sucursal_aut: (state) => state.sucursal_autorizacion,
  url: (state) => state.convenio_url,
  actionCodes: (state) => state.actionCodes,
  errorCodes: (state) => state.actionCodes.error,
};

const mutations = {
  loadingstatus(state, newloadingstatus) {
    state.loadingstatus = newloadingstatus;
  },
  changeUpdateStatus(state, newStatus) {
    state.updateState = newStatus;
  },
  usermodal(state, newusermodal) {
    state.usermodal = newusermodal;
  },
  setLastSession(state, payload) {
    sessionStorage.setItem("lastSession", payload);
    state.lastSession = payload;
  },
  SET_CLAVE_TEMPORAL(state, payload) {
    sessionStorage.setItem("clave_temporal", payload);
    state.clave_temporal = payload;
  },
  SET_TOKEN_TEMPORAL(state, token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    sessionStorage.setItem("token_temporal", token);
    state.token_temporal = token;
  },
  REFRESH_TOKEN(state, token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    sessionStorage.setItem("token", token);
    state.token = token;
  },
  DESTROY_TOKEN(state) {
    sessionStorage.removeItem("token");
    state.token = "";
  },
  SET_USERDATA(state, payload) {
    sessionStorage.setItem("user", JSON.stringify(payload));
    state.user = payload;
    state.perfil = payload.perfil;
  },

  SET_CANALES(state, data) {
    state.canales = data;
    sessionStorage.setItem("canales", JSON.stringify(data));
  },

  SET_CANAL(state, data) {
    state.canal_selected = data;
    sessionStorage.setItem("canal_selected", JSON.stringify(data));
  },

  SET_SUCURSAL(state, data) {
    state.sucursal_selected = data;
    sessionStorage.setItem("sucursal_selected", JSON.stringify(data));
  },

  SET_SUCURSALES(state, data) {
    state.sucursales = data;
    sessionStorage.setItem("sucursales", JSON.stringify(data));
  },

  SET_CANAL_AUTORIZACION(state, data) {
    state.canal_autorizacion = data;
  },
  SET_SUCURSAL_AUTORIZACION(state, data) {
    state.sucursal_autorizacion = data;
  },

  SET_URL_CONVENIO(state, data) {
    state.convenio_url = data;
    sessionStorage.setItem("url_convenio", data);
  },

  CLEAR_USER_DATA() {
    sessionStorage.clear();
    localStorage.clear();
    location.reload();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
