<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" class="ma-0 pa-0">
        <div class="d-grid">
          <div class="justify-self-center pa-1">
            <v-fade-transition>
              <v-img
                :src="SeguroDetails?.[logoName] ?? ''"
                @error="handleLogoError($event, SeguroDetails)"
                width="120"
                max-height="95"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-fade-transition>
          </div>
          <div class="badge primary-bg text-center w-70 mt-3 mb-2">
            <span class="text-white">
              {{
                SeguroDetails?.objeto_vigencia?.vigencia_descripcion ??
                SeguroDetails?.vigencia?.vigencia_descripcion ??
                ""
              }}
            </span>
          </div>
        </div>
      </v-col>

      <v-col cols="9" class="py-0">
        <div class="mb-1">
          <h4 class="bold">
            {{ SeguroDetails?.nombre_producto ?? "--" }}
          </h4>
        </div>
        <h4 class="semiBold" v-if="Cotizacion">
          {{ Cotizacion?.marca_descripcion ?? "" }}
          {{ Cotizacion?.modelo_descripcion ?? "" }}
          {{ Cotizacion?.anio ?? "" }}
        </h4>
        <span class="h6">
          Deducible
          <span class="h5 bold primary--text" v-if="alineacion === 'derecha'">
            {{ SeguroDetails?.deducible ?? 0 }}
            {{ SeguroDetails?.moneda_deducible?.simbolo }}
          </span>
          <span class="h5 bold primary--text" v-else>
            {{ SeguroDetails?.moneda_deducible?.simbolo }}
            {{ SeguroDetails?.deducible ?? 0 }}
          </span>
        </span>
        <br />

        <span class="h6">
          Prima mensual

          <h6 class="pl-3" v-if="SeguroDetails?.prima_mensual">
            {{
              (SeguroDetails?.moneda_local?.precio ||
                SeguroDetails?.prima_mensual ||
                "") | numberFormat
            }}
          </h6>
          <span class="px-3 bold title-color" v-else>
            <!-- {{SeguroDetails.moneda_local.simbolo}} {{SeguroDetails.moneda_local.precio}} -->
            {{ SeguroDetails?.moneda_local?.simbolo ?? "$"
            }}{{ (SeguroDetails?.moneda_local?.precio ?? "0") | numberFormat }}
          </span>
          <span class="grey--text">|</span>
          <span class="px-3 bold primary--text">
            <!-- {{SeguroDetails.moneda_seguro.simbolo}} {{SeguroDetails.moneda_seguro.precio}} -->
            {{ SeguroDetails?.moneda_seguro?.simbolo ?? "UF" }}
            {{
              (SeguroDetails?.moneda_seguro?.precio ||
                SeguroDetails?.prima_mensual_seguro ||
                "") | numberFormat
            }}
          </span>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  mixins: [LogoRequestMixin],
  name: "SeguroDetalle",
  props: ["SeguroDetails", "Cotizacion", "alineacion"],
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.d-grid {
  display: grid;
}
.justify-self-center {
  justify-self: center;
}
.badge {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
