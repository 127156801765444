import axios from "axios";

const state = {
  reports: [],
};

const getters = {
  Reports: (state) => state.reports,
};
const mutations = {
  Reports(state, reports) {
    state.reports = reports;
  },
};

const actions = {
  async getReports({ commit }, data) {
    axios({
      url: `/reportes/usuarios/dashboard`,
      method: "GET",
      params: {
        anio: data.anio,
        mes: data.mes,
      },
    })
      .then((res) => {
        commit("Reports", res.data.data.dashboard);
      })
      .catch(() => {
        commit("Reports", []);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
