export default [
  // Pagina de cotizaciones
  {
    path: "/cotizaciones",
    component: () => import("../views/Sidebar/Cotizaciones.vue"),
    children: [
      {
        name: "quotes",
        path: "/",
        component: () =>
          import(
            "@/components/Pages-Components/Cotizaciones-Components/CotizacionesTable.vue"
          ),
      },
      {
        name: "quotationOffers",
        path: "ofertas/:id",
        component: () =>
          import(
            "@/components/Pages-Components/Cotizaciones-Components/Results/CotizacionesResults.vue"
          ),
        props: true,
      },
      {
        name: "Productos_complementarios",
        path: "/cotizaciones/Productos_complementarios",
        component: () =>
          import(
            "@/components/Pages-Components/Cotizaciones-Components/Results/Productos_complementarios.vue"
          ),
        props: true,
      },
      {
        name: "compare",
        path: "comparison-results",
        component: () =>
          import(
            "@/components/Pages-Components/Cotizaciones-Components/Results/Compare.vue"
          ),
      },
    ],
  },
];
