<template>
  <v-main>
    <v-container fluid class="pa-0 background">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style>
.background {
  background-color: #f4f7fc !important;
  height: 100%;
}
</style>
