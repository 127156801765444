import axios from "axios";
import store from "@/store/index.js";

export const companyLogoName = "companyLogo-";
export const companyNumber = "compania";
export const companyLogoUrl = "compania_logo";
export const defaultCompanyUrl = "/logos";
export const defaultLogoPropName = "companyLogoRequested";

export const requestFile = (url = "") => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((r) => resolve(r.data))
      .catch(() => {
        reject(
          new Error("No se ha logrado recuperar el archivo del servidor.")
        );
      });
  });
};

export const requestImage = async (
  data = { name: "", url: undefined, defaultUrl: "" }
) => {
  let img = "";
  if (data.url && data.name) {
    const cacheImg = sessionStorage.getItem(data.name),
      storeImg = store.getters["Proposal/getCompanyLogo"](data.name);
    if (storeImg) {
      img = storeImg;
    } else if (cacheImg) {
      img = cacheImg;
    } else {
      await requestFile(data.url)
        .then(async (response) => {
          const result = response.data.url;
          img = result;
          if (!img) {
            throw new Error(
              "No se ha logrado recuperar la imagen del servidor"
            );
          }
          sessionStorage.setItem(data.name, result);
          store.commit("Proposal/setCompanyLogo", {
            name: data.name,
            value: result,
          });
        })
        .catch(() => {
          try {
            img = `${data.defaultUrl}/${data.name}.png`;
          } catch {
            img = `${data.defaultUrl}/DEFAULT.png`;
          }
        });
    }
  }
  return img;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = Array.from({ length: 5 });
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadPdfFile = (
  fileData,
  fileType,
  fileDescription = "new-file"
) => {
  const blob = b64toBlob(fileData, fileType);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  const nameDownload = fileDescription.split(".")[0];
  link.download = nameDownload + ".pdf";
  link.click();
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  requestFile,
  requestImage,
  downloadPdfFile,
  fileToBase64,
  b64toBlob,
};
