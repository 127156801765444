<template>
  <v-app-bar app dense class="buy-navbar" elevation="1" color="grey lighten-5">
    <div>
      <!-- <h3
        style="background-color: white; border-radius: 5px"
        v-if="shoppingCart?.complementarios_seleccionados?.length > 0"
      >
        Complementario ({{ shoppingCart.complementarios_seleccionados.length }}) -
        {{ total_complementario_pesos | moneyFormat }} |
        {{ total_complementario_uf | UFPrice }}
      </h3> -->
      <!-- <h3
        style="background-color: white; border-radius: 5px"
        v-if="shoppingCart?.adicionales_seleccionados?.length > 0"
      >
        Adicionales ({{ shoppingCart?.adicionales_seleccionados?.length ?? 0 }}) -
        {{ total_adicionales_pesos | moneyFormat }}/ UF
        {{ total_adicionales_uf.toString().replace(".", ",") }}
      </h3> -->
      <div class="d-flex w-100 align-center">
        <span>
          Monto mensual - {{ cuotas }} cuotas de
          <span class="bold">
            {{ this.tarifa?.moneda_local?.simbolo ?? "$"
            }}{{ totalPesos | numberFormat }} |
            {{ this.tarifa?.moneda_seguro?.simbolo ?? "UF" }}
            {{ totalUf | numberFormat }}
          </span>
        </span>
        <p class="text-lowercase ml-1">
          {{ " - " }}
          <span v-if="tarifa?.moneda_deducible?.alineacion === 'derecha'">
            deducible
            {{ tarifa?.deducible ?? "" }}
            {{ tarifa?.moneda_deducible?.simbolo ?? "" }}
          </span>
          <span v-else>
            deducible
            {{ tarifa?.moneda_deducible?.simbolo ?? "" }}
            {{ tarifa?.deducible ?? "" }}
          </span>

          <!-- <br v-if="shoppingCart?.complementarios_seleccionados?.length > 0" /> -->
          <!-- <strong v-if="shoppingCart?.complementarios_seleccionados?.length > 0"
            >+</strong
          > -->
          <!-- {{
            shoppingCart?.complementarios_seleccionados?.[0]?.plan_descripcion
              ?? ""
          }}
          <br v-if="shoppingCart?.adicionales_seleccionados?.length > 0" />
          <strong v-if="shoppingCart?.adicionales_seleccionados?.length > 0">+</strong> -->
        </p>
        <!-- <span>
          {{
            shoppingCart?.adicionales_seleccionados?.[0]?.plan_descripcion
              ?? ""
          }}
        </span> -->
      </div>
    </div>
    <v-spacer></v-spacer>
    <v-btn
      @click="openModal = true"
      rounded
      outlined
      color="primary"
      small
      class="text-none"
    >
      <v-icon left>mdi-cart-outline</v-icon>
      Ver Carrito
    </v-btn>

    <ResumeCart
      v-model="openModal"
      @close="closeDialog"
      :planData="[tarifa, ...planData]"
      :total_pesos="totalPesos"
      :total_uf="totalUf"
      :cuotas="cuotas"
      :contador="contador"
    />
  </v-app-bar>
</template>
<script>
import { mapGetters } from "vuex";
import ResumeCart from "@/components/Modal/ShoppingCart/ModalCart-card.vue";

export default {
  name: "BuyCardComponent",
  components: {
    ResumeCart,
  },
  // created() {
  // this.countProduct();
  // this.countTotalPlan();
  // },

  data() {
    return {
      contador: 1,
      total_complementario_pesos: 0,
      total_complementario_uf: 0,
      total_adicionales_pesos: 0,
      total_adicionales_uf: 0,
      openModal: false,
      planData: [],
      defaultTarifa: 0,
    };
  },
  methods: {
    closeDialog() {
      this.openModal = false;
    },
    // countProduct() {
    //   this.contador = 0;

    //   if (this.tarifa?.length === 1) {
    //     this.contador += 1;
    //   }
    // if (this.shoppingCart?.adicionales_seleccionados?.length > 0) {
    //   this.contador += this.shoppingCart.adicionales_seleccionados.length;
    // }
    // if (this.shoppingCart?.complementarios_seleccionados?.length > 0) {
    //   this.contador += this.shoppingCart.complementarios_seleccionados.length;
    // }
    // },

    // countTotalPlan() {
    // == Complementarios ==
    // this.tarifa?.complementarios_seleccionados?.map((it) => {
    //   this.total_complementario_pesos += it.moneda_local.precio;
    //   this.total_complementario_uf += it.moneda_seguro.precio;
    //   this.totalPesos += it.moneda_local.precio;
    //   this.totalUf += it.moneda_seguro.precio;
    //   this.planData.push(it);
    // });
    // == Adicionales ==
    // this.shoppingCart?.adicionales_seleccionados?.map((it) => {
    //   this.totalPesos += it.moneda_local.precio;
    //   this.total_adicionales_pesos += it.moneda_local.precio;
    //   this.total_adicionales_uf += it.moneda_seguro.precio;
    //   this.totalUf += it.moneda_seguro.precio;

    //   this.planData.push(it);
    // });
    // },
  },
  computed: {
    ...mapGetters("Proposal", ["shoppingCart", "tarifa"]),

    totalPesos() {
      return this.tarifa?.moneda_local?.precio ?? 0;
    },
    totalUf() {
      return this.tarifa?.moneda_seguro?.precio ?? 0;
    },
    cuotas() {
      return this.tarifa?.objeto_vigencia?.meses ?? this.defaultTarifa;
    },
  },
};
</script>

<style scoped>
.buy-navbar {
  top: 64px !important;
  z-index: 0;
}
</style>
