<template>
  <v-card outlined class="global-card7 mt-2">
    <div class="d-flex align-center">
      <div class="d-flex ml-3 mb-2 global-iconfill">
        <v-icon color="#050C42" size="20px" class="bi bi-shield-lock" />
      </div>
      <div>
        <v-list-item>
          <v-list-item-content class="pt-2">
            <v-list-item-title class="global-titleStyle4">{{
              Data.tipo_descripcion
            }}</v-list-item-title>
            <p class="global-sideText">
              RUT {{ Data.usuario_solicitud | rutFormat }}
            </p>
            <p class="global-sideText">{{ Data.mensaje }}</p>
            <a
              v-on:click="actionBtn"
              class="global-linkStyle"
              :style="{ color: btnColor }"
            >
              Ir a autorizar</a
            >
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>

    <p class="global-Date">{{ Data.fecha_creacion | formatDateDoc }}</p>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    Data: {
      type: [Array, Object],
    },
    actionBtn: {
      type: Function,
    },
  },

  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    btnColor() {
      return this.getBotonColor;
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
a {
  text-decoration: none;
}
</style>
