const getters = {
  getPAT: (state) => {
    return state.PAT;
  },
  getPAC: (state) => {
    return state.PAC;
  },
  getStatusCreatingLink: (state) => {
    return state.create_link;
  },
  getShowModal: (state) => {
    return state.show_modal;
  },
  getShowEmailModal: (state) => {
    return state.show_email_modal;
  },
  getItemShow: (state) => {
    return state.item_show;
  },
  selectedPayment: (state) => {
    let data;
    try {
      data = JSON.parse(state.payment_selected);
    } catch {
      data = state.payment_selected;
    }
    return data;
  },
  bankList: (state) => state.bankList,
};

export default getters;
