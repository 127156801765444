export const errors = {
  4100: {
    code: 4100,
    message: "RUT de Usuario inválido",
    type: "error",
    onInput: true,
    input: "username",
  },
  4101: {
    code: 4101,
    message: "El convenio del usuario no es válido para la plataforma",
    type: "warning",
    onInput: false,
    input: "",
  },
  4102: {
    code: 4102,
    message: "El usuario se encuentra bloqueado",
    type: "warning",
    onInput: false,
    input: "",
  },
  4103: {
    code: 4103,
    message: "Contraseña incorrecta",
    type: "error",
    onInput: true,
    input: "password",
  },
  4109: {
    code: 4109,
    message: "No se ha logrado autenticar en el sistema",
    type: "error",
    onInput: false,
    input: "",
  },
};

export default {
  errors,
};
