const mutations = {
  setPAC(state, PAC) {
    state.PAC = PAC;
  },
  setPAT(state, PAT) {
    state.PAT = PAT;
  },

  setCreateLink(state, status) {
    state.crea_link = status;
  },
  setShowModal(state, status) {
    state.show_modal = status;
  },
  setShowEmailModal(state, status) {
    state.show_email_modal = status;
  },
  setItemShow(state, data) {
    state.item_show = data;
  },
  setSelectedPayment(state, payload) {
    state.payment_selected = payload;
    sessionStorage.setItem("payment_selected", JSON.stringify(payload));
  },
  setBankList(state, payload) {
    state.bankList = payload.filter(
      (e) => e.eliminado !== undefined && !e.eliminado
    );
  },
};

export default mutations;
