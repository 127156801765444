// General Import
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "core-js/actual";
// Plugins
import vuetify from "./plugins/vuetify";
import "./plugins/filters.js";
import "./plugins/directives.js";
import "./plugins/global-components.js";
// ------
import VueCryptojs from "vue-cryptojs";
import { version } from "../package.json";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
if (process.env.VUE_APP_AMBIENTE === "MARSH") {
  axios.defaults.headers.common["jwt-variable"] =
    sessionStorage.getItem("jwt-variable");
  axios.defaults.headers.common["x-api-key"] = process.env.VUE_APP_API_TOKEN;
} else {
  axios.defaults.headers.common["x-mclick-key"] = process.env.VUE_APP_API_TOKEN;
}

Vue.config.productionTip = false;

Vue.use(vuetify);
Vue.use(VueCryptojs);

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$store.commit("setVersion", version);

    const userString = sessionStorage.getItem("user");
    const userToken = sessionStorage.getItem("token");

    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USERDATA", userData);
    }
    if (userToken) {
      this.$store.commit("REFRESH_TOKEN", userToken);
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$router.push({ name: "Error", query: { codigo: 2 } });
        }

        return Promise.reject(error);
      }
    );
  },
  render: (h) => h(App),
}).$mount("#app");
