import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    PAC: null,
    PAT: null,
    create_link: null,
    show_modal: false,
    show_email_modal: false,
    item_show: null,
    bankList: [],
    payment_selected: sessionStorage.getItem("payment_selected") || null,
  },
  actions,
  mutations,
  getters,
};
