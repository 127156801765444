export const twoDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const numericKeys = () => {
  return "(event.charCode >= 48 && event.charCode <= 57)";
};

export const alphaNumericKeys = () => {
  const numbers = "(event.charCode >= 48 && event.charCode <= 57)",
    smallLetters = "(event.charCode >= 97 && event.charCode <= 122)",
    capsLetters = "(event.charCode >= 65 && event.charCode <= 90)",
    asterik = "(event.charCode == 42)";
  return [numbers, smallLetters, capsLetters, asterik].join(" || ");
};

export const dateToNumber = (dateString = "", divider = "/") => {
  return (typeof dateString !== "string" ? dateString.toString() : dateString)
    .split(divider)
    .join("");
};

export const cuotaCalc = (i) =>
  i?.cuota ||
  (this.productMonths <= 12 ? i.cuota_anual : i.cuota_bi_anual) ||
  i.cuota_libre ||
  1;

export const darkorlightStyle = (color = "") => {
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff, // extract red
    g = (rgb >> 8) & 0xff, // extract green
    b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  let newColor = "#5F6368";
  if (luma < 128) {
    newColor = "#F5F5F5";
  }
  return newColor;
};
