const getters = {
  getUsers: (state) => {
    return state.users;
  },

  searchUser: (state) => {
    return state.searchingUser;
  },
  getUserInfo(state) {
    let data;
    try {
      data = JSON.parse(state.userInfo);
    } catch {
      data = state.userInfo;
    }
    return data;
  },

  getOffers: (state) => {
    return state.offers;
  },

  getCategories: (state) => {
    return state.categories;
  },

  getToken: (state) => {
    return state.token;
  },

  getProducts: (state) => (filter) => {
    let products;
    if (filter !== "") {
      products = state.products.filter((it) => it.estado === filter);
    } else {
      products = state.products;
    }
    return products;
  },

  getBrands: (state) => {
    return state.brands;
  },

  getModels: (state) => {
    return state.models;
  },
  getProductDetail: (state) => {
    return state.productDetail;
  },

  getPlanPago: (state) => {
    return state.planPago;
  },

  getProductCategories: (state) => {
    return state.product_categories;
  },

  getQuotationValidate: (state) => {
    return state.quotation_validation;
  },

  getStatusQuotation: (state) => {
    return state.creatingQuotation;
  },
  getErrorQuotation: (state) => {
    return state.errorQuotation;
  },

  getNumberQuotation: (state) => {
    return state.numberQuotation;
  },

  getOffersQuotation: (state) => {
    return state.offersQuotation;
  },

  getQuotationState: (state) => {
    return state.quotationState;
  },

  //Viejos getters para obtener estilos y personalizacion (consultar el modulo Channel para los nuevos)
  getMainLogo: (state, getters, rootState) => {
    return rootState.Channel.styles.main_logo;
  },
  getMenuColor: (state, getters, rootState) => {
    return rootState.Channel.styles.menu_color;
  },
  getBotonColor: (state, getters, rootState) => {
    return rootState.Channel.styles.boton_color;
  },
  getTituloColor: (state, getters, rootState) => {
    return rootState.Channel.styles.titulo_color;
  },
  getTextoColor: (state, getters, rootState) => {
    return rootState.Channel.styles.texto_color;
  },
  getIconoColor: (state, getters, rootState) => {
    return rootState.Channel.styles.icono_color;
  },
  getBoxColor: (state, getters, rootState) => {
    return rootState.Channel.styles.box_color;
  },
  getConvenio: (state) => {
    return state.convenio;
  },
};

export default getters;
