var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"ventaApp",style:(_vm.styles)},[_c('v-fade-transition',[_c('router-view')],1),_c('v-overlay',{attrs:{"value":_vm.loadingScreen}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('base-snackbar',{attrs:{"text":_vm.snackbar.text,"snackbar-active":_vm.snackbar.active,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"position":{
      top: _vm.snackbar.top,
      bottom: _vm.snackbar.bottom,
      right: _vm.snackbar.right,
      left: _vm.snackbar.left,
      centered: _vm.snackbar.centered,
    }},on:{"close":function($event){return _vm.closeSnackbar()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }