/* eslint-disable no-undef */
import axios from "axios";

const state = {
  Documentos: [], // DOCUMENTOS PENDENTES
  type_documents: [],
  DocumentStatus: null,
  success: true,
};

const getters = {
  Documentos: (state) => state.Documentos,
  DocTypes: (state) => state.type_documents,
  Documentstatus: (state) => state.DocumentStatus,
};

const mutations = {
  POST_SUCCESS(state) {
    state.success = true;
  },
  SetDocumentos(state, payload) {
    state.Documentos = payload;
    state.Documentos.sort((a, b) => {
      return b.numero_propuesta - a.numero_propuesta;
    });
  },
};

const actions = {
  GetDocs({ commit }, params = {}) {
    const path = `/documentos/propuestas`;
    if (!params?.estados) {
      params.estados = [2, 3].join();
    }
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
        params,
      })
        .then(async (response) => {
          const result = response.data.data.result,
            data = result?.propuestas ?? [],
            pagination = result?.paginacion ?? {};

          if (!params?.numero_propuesta) {
            data.forEach((e) => {
              e.loading = false;
            });

            await commit("SetDocumentos", data);
          }
          resolve({
            result: data,
            pagination,
          });
        })
        .catch(() => {
          reject("Ha ocurrido un error al intentar recuperar los documentos.");
        });
    });
  },
  UploadDoc({ commit }, data) {
    const path = "/documentos/subir/pendiente";
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((response) => {
          commit("POST_SUCCESS");
          const result = response.data;
          let message;
          if (!result.success) {
            throw result.message;
          } else {
            message =
              result.message || "Se ha cargado el documento correctamente.";
          }

          resolve({ result, info: message });
        })
        .catch((error) => {
          let message =
            "Ha ocurrido un error al intentar cargar un documento al servidor.";
          if (error && typeof error === "string") {
            message = error;
          }
          reject(message);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
