export default [
  // Seccion del Detalle del cliente
  {
    path: "/cliente/:document_type/:document_number",
    name: "UserDetail",
    component: () => import("../views/Users/UserDetail.vue"),
    props: true,
  },
  // Paginas internas
  {
    path: "/cliente/:document_type/:document_number/datos",
    name: "NewEditUser",
    component: () => import("../views/Client/ClientInfoView.vue"),
    props: true,
  },
  {
    path: "/cliente/:document_type/:document_number/nueva-cotizacion",
    name: "NewQuotation",
    component: () => import("../views/Users/Detalle/NewQuotation.vue"),
    props: true,
  },
];
