import axios from "axios";

const actions = {
  getSimulaciones(context, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/simulaciones`,
        method: "GET",
        responseType: "blob",
        params,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default actions;
