export const sidebarPages = [
  {
    id: 1,
    name: "Inicio",
    icon: "home",
    path: "dashboard",
  },
  {
    id: 2,
    name: "Cotizaciones",
    icon: "description",
    path: "quotes",
    permission: 1,
  },
  {
    id: 3,
    name: "Documentos",
    icon: "folder_open",
    path: "documents",
    permission: 1,
  },

  {
    id: 4,
    name: "Descuentos",
    icon: "sell",
    path: "discounts",
    permission: 1,
  },
  {
    id: 5,
    name: "Personas",
    icon: "group",
    path: "people",
    permission: 1,
  },
  {
    id: 6,
    name: "informes",
    icon: "bar_chart",
    path: "reports",
    permission: 1,
  },
];

export default {
  sidebarPages,
};
