import { render, staticRenderFns } from "./TheSideBar.vue?vue&type=template&id=43d384b5&scoped=true"
import script from "./TheSideBar.vue?vue&type=script&lang=js"
export * from "./TheSideBar.vue?vue&type=script&lang=js"
import style0 from "./TheSideBar.vue?vue&type=style&index=0&id=43d384b5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d384b5",
  null
  
)

export default component.exports