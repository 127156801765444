import QuotePages from "./cotizaciones";
import ClientPages from "./client";

export default [
  // Principal
  {
    path: "/panel-de-control",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("../views/Profile/ConfigProfile.vue"),
    props: true,
  },
  // Pagina de Documentos
  {
    path: "/documentos",
    name: "documents",
    component: () => import("@/views/Sidebar/Documentos.vue"),
  },
  // Pagina de Descuentos
  {
    path: "/descuentos",
    name: "discounts",
    component: () => import("@/views/Sidebar/Descuentos.vue"),
  },
  // Pagina de personas
  {
    path: "/personas",
    component: () => import("@/views/Sidebar/Personas.vue"),
    children: [
      {
        name: "people",
        path: "/",
        component: () =>
          import("../components/Pages-Components/Personas/TablePage.vue"),
      },
      {
        name: "Register",
        path: "/personas/register-new-user",
        component: () =>
          import("../components/Pages-Components/Personas/Register.vue"),
      },
    ],
  },
  {
    path: "/informes",
    name: "reports",
    component: () => import("../views/Sidebar/Informes.vue"),
  },
  {
    path: "/contratacion/:id",
    name: "Contract",
    component: () => import("../views/Users/Contract.vue"),
    props: true,
  },

  {
    path: "/carrito/:propuesta",
    name: "ShoppingCart",
    component: () => import("../views/Users/Carrito.vue"),
    props: true,
  },

  ...QuotePages,
  ...ClientPages,
];
