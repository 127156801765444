const mutations = {
  setQuotNumber(state, payload) {
    state.quotNumber = payload;
  },
  setQuotValidacionData(state, payload) {
    state.quotValidation = payload;
  },
  SetQuots(state, payload) {
    state.quots = payload;
  },
};

export default mutations;
