import Codes from "@/helpers/countriesCodes.js";

export default {
  data: () => ({
    langText: null,
  }),
  created() {
    this.loadJsonData();
  },
  watch: {
    country: function (newVal) {
      if (newVal) {
        this.loadJsonData(newVal);
      }
    },
  },
  computed: {
    country() {
      return this.$store.getters.getCountry;
    },
    countryCodes() {
      return Codes;
    },
    isChile() {
      return this.country?.code === this.countryCodes["CL"];
    },
    isPeru() {
      return this.country?.code === this.countryCodes["PE"];
    },
  },
  methods: {
    isCountry(code = "") {
      return this.country?.code === this.countryCodes[code];
    },
    async loadJsonData(country = null) {
      let jsonData;
      const code = country?.code ?? this.country?.code ?? null;
      if (code) {
        try {
          jsonData = await import(`@/assets/lang/${this.country?.code}.json`);
          this.langText = jsonData.data;
        } catch (error) {
          throw new Error(`Invalid country code: ${error}`);
        }
      }
    },
  },
};
