<template>
  <div class="text-center">
    <v-dialog persistent :value="open" max-width="950" attach="#main-container">
      <v-card :loading="loadShare || loadingquot" height="645">
        <v-card-title primary-title>
          Detalle del producto
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="text-none mx-3"
            small
            v-if="showQuoteActions"
            :disabled="loadShare"
            @click="sendQuotation(selected)"
          >
            <v-icon left>mdi-share-variant</v-icon>
            Compartir Cotización
          </v-btn>

          <v-btn icon small @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <div class="d-flex">
            <cardPlan
              :Cotizacion="selected"
              :SeguroDetails="selected"
              :alineacion="alineacion"
            />
          </div>
          <div
            class="w-100 main-background-color py-4 px-5 d-flex justify-space-between"
          >
            <v-row>
              <v-col>
                <v-card flat class="bordered" height="350">
                  <div class="card-content overflow-y-auto">
                    <v-card-title class="cardTitle">
                      <h4>Coberturas</h4>
                    </v-card-title>
                    <v-card-text>
                      <div
                        v-for="(item, i) in coberturas"
                        :key="i"
                        class="my-4"
                      >
                        <div class="d-flex align-center">
                          <div><v-icon>mdi-account-check</v-icon></div>
                          <div class="ml-4">
                            <span class="bold">{{ item.titulo }}</span
                            ><br />
                            <span>{{ item.descripcion }}</span>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
              <v-col>
                <v-card flat class="bordered" height="350">
                  <div class="card-content overflow-y-auto">
                    <v-card-title class="cardTitle">
                      <h4>Exclusiones</h4>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="exclusiones.length > 0">
                        <div
                          v-for="(item, i) in exclusiones"
                          :key="i"
                          class="my-2"
                        >
                          <div class="d-flex align-center">
                            <div>
                              <v-icon>mdi-account-minus</v-icon>
                            </div>
                            <div class="ml-4">
                              <span class="bold">{{ item.titulo }}</span
                              ><br />
                              <span>{{ item.descripcion }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>Este producto no tiene exclusiones</div>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions v-if="showQuoteActions">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none px-6 py-6"
            :loading="loadingquot"
            outlined
            rounded
            @click="GenerateQuotation(selected)"
          >
            Descargar simulación</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cardPlan from "@/components/Cards/Plan-card";

export default {
  name: "ModalDetallePropuesta",
  props: {
    loadingquot: {
      type: Boolean,
      default: false,
    },
    loadShare: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: null,
    },
    showQuoteActions: {
      type: Boolean,
      default: true,
    },
    share: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    cardPlan,
  },
  methods: {
    GenerateQuotation(item) {
      this.$emit("download", item);
    },
    sendQuotation(item) {
      this.$emit("share", item);
    },
  },
  computed: {
    coberturas() {
      return (this.selected?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo === "C"
      );
    },
    exclusiones() {
      return (this.selected?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo === "E" || item.tipo === "O"
      );
    },
    alineacion() {
      return this.selected?.moneda_deducible?.alineacion ?? "izquierda";
    },
  },
};
</script>
