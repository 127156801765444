const mutations = {
  setSearchingUser(state, searching) {
    state.searchingUser = searching;
  },
  setDataUser(state, payload) {
    sessionStorage.setItem("userInfo", JSON.stringify(payload));
    state.userInfo = payload;
  },

  setOffers(state, offers) {
    state.offers = offers;
  },

  compare(a, b) {
    if (a.desde > b.desde) {
      return -1;
    }
    if (a.desde < b.desde) {
      return 1;
    }
    return 0;
  },

  setProducts(state, products) {
    products.sort((a, b) => {
      return b.desde - a.desde;
    });

    state.products = products;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setBrands(state, brands) {
    state.brands = brands;
  },

  setModels(state, models) {
    state.models = models;
  },

  setProductCategories(state, productCategories) {
    state.product_categories = productCategories;
  },
  setProductDetail(state, productDetail) {
    state.productDetail = productDetail;
  },

  setPlanPago(state, planPago) {
    state.planPago = planPago;
  },

  changeStatusQuotation(state, status) {
    state.creatingQuotation = status;
  },

  changeErrorQuotation(state, status) {
    state.errorQuotation = status;
  },

  setOffersQuotation(state, offers) {
    state.offersQuotation = offers;
  },

  setQuotationState(state, status) {
    state.quotationState = status;
  },
  setClearState(state) {
    state.userInfo = {};
    state.offers = [];
    state.quotationsUser = [];
    state.documentsUser = [];
    state.products = [];
    state.categories = [];
    state.documentos = [];
  },
  setConvenio(state, convenio) {
    state.convenio = convenio;
    sessionStorage.setItem("convenio", convenio);
  },
};

export default mutations;
