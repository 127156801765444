import axios from "axios";
import { dateToNumber } from "@/store/resources/calc.js";
import { companyLogoUrl, defaultLogoPropName } from "@/services/file.js";
import { endpoints, idKeys } from "@/helpers/endpoints.js";

export const actions = {
  //validar si un usuario existe
  findUser: async function ({ commit }, { document_type, document_number }) {
    // Payload => .rut del Cliente
    const url = `${endpoints.client.base}/${document_type}/${document_number}`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          const result = response.data;
          resolve({ result, client: result.data.cliente });
          commit("setSearchingUser", false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // Establecer la informacion del cliente
  setClient: ({ commit }, { document_number, document_type }) => {
    // Payload => rut del Cliente
    const url = `${endpoints.client.base}/${document_type}/${document_number}`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          const client = response.data?.data?.cliente || null,
            tipoPersona = client?.tipo_persona ?? "NAT",
            tipoCliente =
              tipoPersona === "NAT" ? client?.cliente : client.contacto;

          const result = {
            ...tipoCliente,
            tipo_persona: tipoPersona,
            razon_social: client?.cliente?.razon_social ?? "",
            comuna: client?.cliente?.comuna ?? undefined,
            comuna_descripcion:
              client?.cliente?.comuna_descripcion ?? undefined,
            region: client?.cliente?.region ?? undefined,
            provincia: client?.cliente?.provincia ?? undefined,
            policy: response.data?.codigo_accion == 1,
          };
          commit("setDataUser", client);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Crear usuarios
  createUser(context, data = null) {
    const url = endpoints.client.base;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject({
            message: "Ha ocurrido un error y no se ha logrado crear el cliente",
            error: err,
          });
        });
    });
  },
  deleteUserData({ state }) {
    state.userInfo = null;
    sessionStorage.removeItem("userInfo");
  },
  // usuario natural
  createUserNatural: function ({ dispatch }, data) {
    return dispatch("createUser", {
      avisos_privacidad: data?.avisos_privacidad ?? [],
      tipo_persona: "NAT",
      comuna: data.comuna?.value ?? "",
      comuna_descripcion: data.comuna?.label ?? "",
      region: data.region?.value ?? "",
      region_descripcion: data.region?.label ?? "",
      provincia: data.provincia?.value ?? "",
      provincia_descripcion: data.provincia?.label ?? "",
      cod_pais: data.cod_pais ?? "",
      cliente: {
        nombre: data.nombre,
        tipo_documento: data.tipo_documento,
        numero_documento: data.numero_documento,
        primer_apellido: data.primer_apellido,
        segundo_apellido: data.segundo_apellido,
        fecha_nacimiento: dateToNumber(
          data.fecha_nacimiento.split("/").join("") || ""
        ),
        genero: data.genero || "M",
        datos_contacto: {
          email: data.email,
          telefono: data.telefono,
          otro: data.otro,
        },
      },
    });
  },

  //crear usuario juridico
  createUserJuridico: function ({ dispatch }, data) {
    return dispatch("createUser", {
      avisos_privacidad: data?.avisos_privacidad ?? [],
      tipo_persona: "JUR",
      comuna: data.comuna?.value ?? "",
      comuna_descripcion: data.comuna?.label ?? "",
      region: data.region?.value ?? "",
      region_descripcion: data.region?.label ?? "",
      provincia: data.provincia?.value ?? "",
      provincia_descripcion: data.provincia?.label ?? "",
      cod_pais: data.cod_pais ?? "",
      cliente: {
        razon_social: data.razon_social,
        tipo_documento: data.tipo_documento,
        numero_documento: data.numero_documento,
      },
      contacto: {
        tipo_documento: data.tipo_documento,
        numero_documento: data.numero_documento,
        nombre: data.nombre,
        primer_apellido: data.primer_apellido,
        segundo_apellido: data.segundo_apellido,
        fecha_nacimiento: dateToNumber(
          data.fecha_nacimiento?.split("/")?.join("") || ""
        ),
        genero: data.genero || "M",
        giro: data?.giro ?? "",
        actividad_comercial: data?.actividad_comercial ?? "",
        datos_contacto: {
          email: data.email,
          telefono: data.telefono,
          otro: data.otro,
        },
      },
    });
  },

  // editar usuario natural
  editUserNatural: async function (context, data) {
    const url = `${endpoints.client.base}/${data.tipo_documento}/${data.numero_documento}`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "PUT",

        data: {
          avisos_privacidad: data?.avisos_privacidad ?? [],
          tipo_persona: "NAT",
          comuna: data.comuna?.value ?? "",
          comuna_descripcion: data.comuna?.label ?? "",
          region: data.region?.value ?? "",
          region_descripcion: data.region?.label ?? "",
          provincia: data.provincia?.value ?? "",
          provincia_descripcion: data.provincia?.label ?? "",
          cod_pais: data.cod_pais || "",
          cliente: {
            nombre: data.nombre,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            genero: data.genero,
            fecha_nacimiento: data.fecha_nacimiento.split("/").join(""),
            datos_contacto: {
              email: data.email,
              telefono: data.telefono,
              otro: data.otro,
            },
          },
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //editar usuario juridico
  editUserJuridico: async function (context, data) {
    const url = `${endpoints.client.base}/${data.tipo_documento}/${data.numero_documento}`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "PUT",

        data: {
          avisos_privacidad: data?.avisos_privacidad ?? [],
          tipo_persona: "JUR",
          comuna: data.comuna?.value ?? "",
          comuna_descripcion: data.comuna?.label ?? "",
          region: data.region?.value ?? "",
          region_descripcion: data.region?.label ?? "",
          provincia: data.provincia?.value ?? "",
          provincia_descripcion: data.provincia?.label ?? "",
          cod_pais: data.cod_pais ?? "",
          cliente: {
            razon_social: data.razon_social,
            giro: data?.giro ?? "",
            actividad_comercial: data?.actividad_comercial ?? "",
          },
          contacto: {
            tipo_documento: data.tipo_documento,
            numero_documento: data.numero_documento,
            nombre: data.nombre,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            genero: data.genero,
            fecha_nacimiento: data.fecha_nacimiento.split("/").join(""),
            datos_contacto: {
              email: data.email,
              telefono: data.telefono,
              otro: data.otro,
            },
          },
        },
      })
        .then((response) => {
          resolve({
            message: "¡Se ha actualizado el cliente correctamente!",
            result: response.data.data,
          });
        })
        .catch((err) => {
          reject({
            message: "Ha ocurrido un error al intentar actualizar un cliente.",
            error: err,
          });
        });
    });
  },
  findOffersUser: async function ({ commit }, data) {
    const url = endpoints.client.deals.replace(idKeys.docId, data);
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data);
          commit("setOffers", res.data.data.ofertas);
        })
        .catch((err) => {
          commit("setOffers", []);
          reject(err);
        });
    });
  },

  findProductsUser: function ({ commit }, data) {
    const params = {};
    if (data?.nroProp) {
      params.propuesta = data.nroProp;
    }
    const url = endpoints.client.products.replace(
      idKeys.docId,
      data?.nroDoc ?? ""
    );
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
        params: params,
      })
        .then(async (res) => {
          const result = res.data?.data?.productos.map((it) => {
            it.canceling = false;
            (it.documentos ?? [])?.forEach((doc) => {
              doc.fecha_creacion_doc = doc.fecha_creacion;
            });
            it[defaultLogoPropName] = it[companyLogoUrl];
            return it;
          });
          commit("setProducts", result);
          resolve(result);
        })
        .catch((err) => {
          commit("setProducts", []);
          reject(err);
        });
    });
  },
  findQuotations: async function ({ commit }, payload) {
    const url = endpoints.client.quots.replace(idKeys.docId, payload);
    const params = {
      codigo_estado: "PEN",
    };
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
        params,
      })
        .then((response) => {
          resolve(response.data.data);
          commit("setQuotations", response.data.data.cotizaciones);
        })
        .catch((err) => {
          reject(err);
          commit("setQuotations", []);
        });
    });
  },
  findAllQuotations: async function ({ commit }, payload) {
    const url = endpoints.client.quots.replace(idKeys.docId, payload);
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          response.data.data.cotizaciones.map((it, index) => {
            it.plazo_retoma = [];

            it.tarifas.ofertas.map((pr) => {
              it.plazo_retoma.push({
                plazo_retoma: pr.plazo_retoma,
                plan: pr.plan,
                deducible: pr.deducible,
                compania: pr.compania_descripcion,
                indice: index,
              });
            });
          });

          resolve(response.data.data.cotizaciones);
          commit("setQuotationsUser", response.data.data.cotizaciones);
        })
        .catch((err) => {
          reject(err);
          commit("setQuotationsUser", []);
        });
    });
  },
  findDocuments: async function ({ commit }, payload) {
    const url = endpoints.client.docs.replace(idKeys.docId, payload);
    const params = {
      estado: "PEN",
    };
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
        params,
      })
        .then((response) => {
          commit("setDocuments", response.data.data.documentos);
          resolve(response.data.data);
        })
        .catch((err) => {
          commit("setDocuments", []);
          reject(err);
        });
    });
  },
  findAllDocuments: async function ({ commit }, payload) {
    const url = endpoints.client.docs.replace(idKeys.docId, payload);
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((response) => {
          commit("setDocumentsUser", response.data.data.propuestas);
          resolve(response.data.data);
        })
        .catch((err) => {
          commit("setDocumentsUser", []);
          reject(err);
        });
    });
  },
  findCategories: async function ({ commit }) {
    const url = endpoints.product.categories;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          const result = response.data.data.result;
          commit("setCategories", result);
          resolve({
            result,
          });
        })
        .catch(() => {
          commit("setCategories", []);
          reject(new Error("No se ha logrado recuperar las categorias."));
        });
    });
  },

  findProductDetail: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/productos/ABC123`,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data;
          commit("setProductDetail", result.producto);
          commit("setPlanPago", result.producto.medios_pago.plan_pago);
          resolve(result.producto);
        })
        .catch((err) => {
          commit("setProductDetail", {});

          reject(err);
        });
    });
  },
  findBrands: async function ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `marcas`,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data;
          commit("setBrands", result.Marcas);
          resolve(result.Marcas);
        })
        .catch((err) => {
          commit("setBrands", []);

          reject(err);
        });
    });
  },
  findBrandsType: async function ({ commit }, payload) {
    // Payload => Id tipo vehiculo
    const path = `marcas/tipo_vehiculo/${
      payload.tipo_vehiculo ? payload.tipo_vehiculo : payload
    }`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          const result = res.data.data;
          commit("setBrands", result.Marcas);
          resolve(result.Marcas);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findModels: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `modelos/tipo_vehiculo/${data.tipo_vehiculo}/marcas/${data.marca}`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data.Modelos);
          commit("setModels", res.data.data.Modelos);
        })
        .catch((err) => {
          commit("setModels", []);
          reject(err);
        });
    });
  },
  findProductCategories: async function ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `productos`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data.producto);

          commit("setProductCategories", {});
          const arrayProducts = {
            label: "Tipo de producto",
            answers: [],
            selected: "",
            placeholder: "Tipo de producto",
            rules: [(value) => !!value || "Campo requerido."],
          };
          res.data.data.producto.map((it) => {
            arrayProducts.answers.push({
              label: it.producto_descripcion,
              value: it.producto,
            });
          });
          commit("setProductCategories", arrayProducts);
        })
        .catch((err) => {
          commit("setProductCategories", {});

          reject(err);
        });
    });
  },
};

export default actions;
