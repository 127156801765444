<template>
  <v-card class="light px-4 py-3 invisible" elevation="0">
    <div class="d-flex justify-space-between align-center border">
      <span class="h4 semiBold">Resumen de compra</span>
      <span class="h6">A pagar</span>
    </div>
    <div
      v-for="(itms, i) in planData"
      :key="i"
      class="d-flex justify-space-between align-center my-4"
    >
      <span class="h5"> {{ itms.plan_descripcion }}</span>
      <span class="h5">
        {{ itms?.moneda_local?.simbolo ?? "$"
        }}{{ (itms?.moneda_local?.precio ?? "0") | numberFormat }} |
        {{ itms?.moneda_seguro?.simbolo ?? "UF" }}
        {{ (itms?.moneda_seguro?.precio ?? "0") | numberFormat }}</span
      >
    </div>
    <div class="d-flex justify-space-between align-center mt-8">
      <span class="h4 semiBold">A pagar</span>
      <span class="h4 semiBold">
        {{ TotalPrice.localSymbol }}{{ TotalPrice.localTotal | numberFormat }} |
        <span class="primary--text semiBold"
          >{{ TotalPrice.seguroSymbol }}
          {{ TotalPrice.seguroTotal | numberFormat }}</span
        ></span
      >
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    planData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    TotalPrice() {
      let localTotal = 0;
      let seguroTotal = 0;
      this.planData.forEach((element) => {
        localTotal += element.moneda_local?.precio ?? 0;
        seguroTotal += element.moneda_seguro?.precio ?? 0;
      });
      const primerObjeto = this.planData[0];
      const total = {
        seguroTotal,
        localTotal,
        seguroSymbol: primerObjeto?.moneda_seguro?.simbolo ?? "UF",
        localSymbol: primerObjeto?.moneda_local?.simbolo ?? "$",
      };
      return total;
    },
  },
};
</script>
<style scoped>
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.v-list {
  display: block;
  padding: 0;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.border {
  border-bottom: 1px #ccc solid;
}
</style>
