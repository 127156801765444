import Vue from "vue";
import Vuex from "vuex";
import Authentication from "./Modules/Authentication/Authentication";
import Dashboard from "./Modules/Dashboard/Dashboard";
import Users from "./Modules/Users";
import Cotizaciones from "./Modules/Dashboard/Cotizaciones";
import Documents from "./Modules/Dashboard/Documentos";
import Personas from "./Modules/Dashboard/Personas";
import Proposal from "./Modules/Proposal";
import Payment from "./Modules/Payment";
import Reports from "./Modules/Dashboard/Reportes";
import Channel from "@/store/Modules/Channel";
import Discounts from "@/store/Modules/Discounts";
import Informes from "@/store/Modules/Reports";
import Quot from "@/store/Modules/Quot";

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    version: sessionStorage.getItem("version") || 0,
  }),
  mutations: {
    setVersion(state, payload) {
      sessionStorage.setItem("version", payload);
      state.version = payload;
    },
  },
  modules: {
    Authentication,
    Dashboard,
    Users,
    Documents,
    Quot,
    Cotizaciones,
    Personas,
    Proposal,
    Payment,
    Reports,
    Channel,
    Discounts,
    Informes,
  },
});
