import axios from "axios";

const state = {
  NotificationStatus: false,
  Error: false,
  cart: [],
  cart_detail: JSON.parse(sessionStorage.getItem("cart_detail")) || {},
  cart_products: JSON.parse(sessionStorage.getItem("cart_products")) || {},
  Items: [],
  addedToCart: null,
  loadInfo: null,
  wereLoaded: null,
  convenio: null,
  // PANTALLA DE CARGA
  loadingScreen: false,
  // MENSAJE DE NOTIFICACION
  snackbar: {
    active: false,
    text: "",
    timeout: 5000,
    color: "",
    top: false,
    bottom: false,
    right: false,
    left: false,
    centered: false,
  },
};

const getters = {
  // OBTENER ESTADO DEL MENSAJE DE NOTIFICACION
  GetSnackbar(state) {
    return state.snackbar;
  },
  // OBTENER EL ESTADO DE LA PANTALLA DE CARGA
  GetLoadingScreen(state) {
    return state.loadingScreen;
  },
  // NAVBAR NOTIFICATION
  Notification: (state) => state.NotificationStatus,
  // DASHBOARD ERROR
  Error: (state) => state.Error,

  Cart: (state) => state.cart,
  CartDetail: (state) => state.cart_detail,
  CartProducts: (state) => state.cart_products,
  Items: (state) => state.Items,
  addedToCart: (state) => state.addedToCart,
  loadInfo: (state) => state.loadInfo,
  wereLoaded: (state) => state.wereLoaded,
  convenio: (state) => state.convenio,
};

const mutations = {
  setSnackbar(state, payload) {
    if (payload?.text) {
      state.snackbar.text = payload?.text;
    }
    if (payload?.active) {
      state.snackbar.active = payload?.active;
    }
    if (payload?.timeout) {
      state.snackbar.timeout = payload?.timeout;
    }
    if (payload?.color) {
      state.snackbar.color = payload?.color;
    }
    if (payload?.top) {
      state.snackbar.top = payload?.top;
    }
    if (payload?.left) {
      state.snackbar.left = payload?.left;
    }
    if (payload?.right) {
      state.snackbar.right = payload?.right;
    }
    if (payload?.bottom) {
      state.snackbar.bottom = payload?.bottom;
    }
    if (payload?.centered) {
      state.snackbar.centered = payload?.centered;
    }
  },
  setLoadingScreen(state) {
    state.loadingScreen = !state.loadingScreen;
  },
  SET_CONVENIO(state, payload) {
    state.convenio = payload;
  },
  // NAVBAR NOTIFICATION
  Notification(state) {
    state.NotificationStatus = !state.NotificationStatus;
  },
  SET_items(state, item) {
    state.addedToCart = true;
    state.Items.push(item);

    setTimeout(function () {
      state.addedToCart = false;
    }, 2000);
  },
  Delete_items(state, item) {
    state.Items.splice(item, 1);
  },

  Error(state) {
    state.Error = !state.Error;
  },

  UpdateCart(state, Items) {
    state.cart = Items;
  },
  UpdateCartDetail(state, Items) {
    state.cart_detail = Items;

    sessionStorage.setItem("cart_detail", JSON.stringify(Items));
  },
  UpdateCartProducts(state, Items) {
    state.cart_products = Items;

    sessionStorage.setItem("cart_products", JSON.stringify(Items));
  },
  loader(state, item) {
    state.loadInfo = item;
  },
  wereLoaded(state) {
    state.wereLoaded = true;
  },
};

export const actions = {
  NotificationBar({ commit }) {
    commit("Notification");
  },

  Error({ commit }) {
    commit("Error");
  },

  GetCartItems({ commit }, payload = null) {
    const propuesta =
      payload || this.state.Cotizaciones?.propuesta?.numero_propuesta;
    console.log(payload);
    if (propuesta) {
      axios({
        url: `carrito/${propuesta}`,
        method: "GET",
      })
        .then((response) => {
          const Items = response.data.data.oferta_seleccionada
            .concat(response.data.data.complementarios_seleccionados)
            .concat(response.data.data.adicionales_seleccionados);

          var dataResume = {
            oferta_seleccionada: null,
            adicionales_seleccionados: null,
            complementarios_seleccionados: null,
          };

          dataResume.oferta_seleccionada =
            response.data.data.oferta_seleccionada;

          dataResume.adicionales_seleccionados =
            response.data.data.adicionales_seleccionados;

          dataResume.complementarios_seleccionados =
            response.data.data.complementarios_seleccionados;

          commit("UpdateCart", Items);
          commit("UpdateCartDetail", dataResume);
          commit("UpdateCartProducts", Items);
        })
        .catch(() => {});
    }
  },

  deleteItem({ dispatch }, Items) {
    this.deleteloader = true;
    axios({
      url: "carrito/3333",
      method: "DELETE",
      data: {
        item: "complementario",
        plan: Items.plan,
      },
    })
      .then(() => {
        dispatch("GetCartItems");
      })
      .catch(() => {});
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
